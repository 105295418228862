<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>Volleyball Life Ratings</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
      <div class="text-h6">What are ratings?</div>
      A rating is simply a letter grade awarded to top finisher in ratings sanctioned events on the Volleyball Life platform.
    </v-card-text>
    <v-card-text class="pb-0">
      <div class="text-h6">What are ratings for?</div>
      <p>Our goal is to have the most accurate seeding into events.  Point systems, which often reward players based on participations, are good but not always the best depiction of talent.  We developed our rating system to be used in combination with the current point systems.  We believe that using this combination, will allow for more accurately seeded teams into all beach volleyball events hosted by Volleyball Life.</p>
      <p>We took our 4 years of data, with over 11,000 events and applied a rule set described in the "Division Sanctioning" section below to determine who received what rating.</p>
      <p>Unlike other systems out there, the tournament director doesn’t decide if the tournament is a AAA, AA, A, BB, or B rated tournament, rather it is determined by the talent that signs up for the tournament.  The more rated players sign up for any tournament, the more likely players will be earning a higher rating.  Tournament directors will have the option to display or not display this information.</p>
    </v-card-text>
    <v-card-text class="pb-0">
      <v-row dense>
        <v-col cols="12" md="6">
          <div class="text-h6">What ratings are available and how are they earned?</div>
          <div class="text-subtitle-2">Juniors:</div>
          <p>Ratings available for juniors are B, A, AA and AAA. Additionally, these ratings are awarded in each of the 4 major age groups (12U, 14U, 16U & 18U).</p>
          <p>The rating awarded is based on the division played, not the age of the player. For example, if a player wins an 18U division that is sanctioned as AA, the player will earn an 18U AAA, regardless of the age of the player. This can result in a player having multiple ratings in different age groups. The players rating badge on their profile will reflect the highest rating the player has earned for their current age group (with the exception of AAAs).</p>
          <div class="text-subtitle-2">Adults:</div>
          <p>Ratings available for adults are B, BB, A, AA and AAA. Adult ratings are only awarded in adult divisions and are awarded based on the division sanctioning, not the title of the division. In other words, if a division is listed as an "A" division, but the sanctioning rules calculate the division as a "B" division... the ratings awarded will be that of a "B" division.</p>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center text-center">
          <v-card color="blue" style="max-width: 300px; min-width: 300px">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr class="color2">
                    <th class="text-center color2Text--text">
                      Adult
                    </th>
                    <th class="text-center color2Text--text">
                      18U
                    </th>
                    <th class="text-center color2Text--text">
                      16U
                    </th>
                    <th class="text-center color2Text--text">
                      14U
                    </th>
                    <th class="text-center color2Text--text">
                      12U
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AAA</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>AA</td>
                    <td>AAA</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>A</td>
                    <td>AA</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>A</td>
                    <td>AAA</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>BB</td>
                    <td></td>
                    <td>AA</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>B</td>
                    <td>A</td>
                    <td>AAA</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>AA</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>B</td>
                    <td>A</td>
                    <td>AAA</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>AA</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>B</td>
                    <td>A</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>B</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pb-0">
      <div class="text-h6">How long does a rating last?</div>
      <p>All Volleyball Life ratings will be valid for 2 seasons including the season it was awarded. For the sake of ratings, a season is from September 1st to October 31st of the following year. At the end of the 2nd season the rating will be dropped 1 letter grade (unless re-earned) and will continue to drop 1 letter every year thereafter.</p>
    </v-card-text>
    <v-card-text class="pb-0">
      <div class="text-h6">Division Sanctioning</div>
      <p>
        In order for a rating to be awarded a division must meet the "Division Sanctioning" requirments. The requirements are a combination of number of teams and number of rated players. The list of requirements is below.
        <ul>
          <li>12 or more teams with a minimum of 8 rated players</li>
          <li>9-11 teams with a minimum of 10 rated players</li>
          <li>6-8 teams with a minimum of 12 rated players</li>
        </ul>
        Any division with 4-6 teams will be "Unrated" and any team with less than 4 teams will not be sanctioned (and therefore no ratings will be issued).
      </p>
    </v-card-text>
    <v-card-text class="pb-0">
      <div class="text-h6">What ratings are awarded?</div>
      <p>Here is a simple calculator that you can play with, to see what ratings will be awarded based on the number of teams and players you enter.</p>
      <rating-calculator></rating-calculator>
    </v-card-text>
  </v-card>
</template>

<script>
import RatingCalculator from '@/components/Ratings/Explanation/RatingCalculator'

export default {
  data () {
    return {
      ratings: [
        {
          rating: 'B',
          headers: [
            { text: '4-7', value: 4, align: 'center' },
            { text: '8-11', value: 8, align: 'center' },
            { text: '12-16', value: 12, align: 'center' },
            { text: '16+', value: 16, align: 'center' }
          ],
          earningRows: [
            {
              finish: 1,
              4: 'A',
              8: 'A',
              12: 'A',
              16: 'A'
            },
            {
              finish: 2,
              4: 'B',
              8: 'B',
              12: 'A',
              16: 'A'
            },
            {
              finish: 3,
              4: null,
              8: 'B',
              12: 'B',
              16: 'B'
            },
            {
              finish: 4,
              4: null,
              8: null,
              12: 'B',
              16: 'B'
            },
            {
              finish: 5,
              4: null,
              8: null,
              12: null,
              16: 'B'
            },
            {
              finish: 7,
              4: null,
              8: null,
              12: null,
              16: null
            },
            {
              finish: 9,
              4: null,
              8: null,
              12: null,
              16: null
            }
          ],
          order: 1
        },
        {
          rating: 'A',
          headers: [
            { text: '4-7', value: 4, align: 'center' },
            { text: '8-11', value: 8, align: 'center' },
            { text: '12-16', value: 12, align: 'center' },
            { text: '16+', value: 16, align: 'center' }
          ],
          earningRows: [
            {
              finish: 1,
              4: 'A',
              8: 'A',
              12: 'AA',
              16: 'AA'
            },
            {
              finish: 2,
              4: 'B',
              8: 'A',
              12: 'A',
              16: 'A'
            },
            {
              finish: 3,
              4: null,
              8: 'B',
              12: 'A',
              16: 'A'
            },
            {
              finish: 4,
              4: null,
              8: null,
              12: 'B',
              16: 'A'
            },
            {
              finish: 5,
              4: null,
              8: null,
              12: 'B',
              16: 'B'
            },
            {
              finish: 7,
              4: null,
              8: null,
              12: null,
              16: 'B'
            },
            {
              finish: 9,
              4: null,
              8: null,
              12: null,
              16: null
            }
          ],
          order: 2
        },
        {
          rating: 'AA',
          headers: [
            { text: '4-7', value: 4, align: 'center' },
            { text: '8-11', value: 8, align: 'center' },
            { text: '12-16', value: 12, align: 'center' },
            { text: '16+', value: 16, align: 'center' }
          ],
          earningRows: [
            {
              finish: 1,
              4: 'AA',
              8: 'AA',
              12: 'AAA',
              16: 'AAA'
            },
            {
              finish: 2,
              4: 'A',
              8: 'AA',
              12: 'AA',
              16: 'AA'
            },
            {
              finish: 3,
              4: 'B',
              8: 'A',
              12: 'AA',
              16: 'AA'
            },
            {
              finish: 4,
              4: null,
              8: 'B',
              12: 'A',
              16: 'AA'
            },
            {
              finish: 5,
              4: null,
              8: null,
              12: 'A',
              16: 'A'
            },
            {
              finish: 7,
              4: null,
              8: null,
              12: 'B',
              16: 'A'
            },
            {
              finish: 9,
              4: null,
              8: null,
              12: null,
              16: 'B'
            }
          ],
          order: 3
        },
        {
          rating: 'AAA',
          headers: [
            { text: '4-7', value: 4, align: 'center' },
            { text: '8-11', value: 8, align: 'center' },
            { text: '12-47', value: 12, align: 'center' },
            { text: '48+', value: 48, align: 'center' }
          ],
          earningRows: [
            {
              finish: 1,
              4: 'AA',
              8: 'AAA',
              12: 'AAA',
              48: 'AAA'
            },
            {
              finish: 2,
              4: 'A',
              8: 'AA',
              12: 'AAA',
              48: 'AAA'
            },
            {
              finish: 3,
              4: null,
              8: 'A',
              12: 'AA',
              48: 'AAA'
            },
            {
              finish: 4,
              4: null,
              8: 'A',
              12: 'AA',
              48: 'AA'
            },
            {
              finish: 5,
              4: null,
              8: null,
              12: 'A',
              48: 'AA'
            },
            {
              finish: 7,
              4: null,
              8: null,
              12: null,
              48: 'AA'
            },
            {
              finish: 9,
              4: null,
              8: null,
              12: null,
              48: 'A'
            }
          ],
          order: 4
        }
      ]
    }
  },
  components: {
    RatingCalculator
  }
}
</script>
