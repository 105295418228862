<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4">
        <v-select
          label="Division Rating"
          :items="['AAA', 'AA', 'A', 'B', 'Unrated', 'Unsanctioned']"
          v-model="divRating"
          clearable
          color="color3"
          dense hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          label="Number Of Teams"
          type="number"
          v-model="teams"
          clearable
          color="color3"
          dense hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="3">
        <v-select
          label="B Rated Players"
          hint="Enter the number of B rated players"
          persistent-hint
          v-model="b"
          :items="bOptions"
          clearable
          color="color3"
          item-color="color3"
          :disabled="bOptions.length === 1"
          dense hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-select
          label="A Rated Players"
          hint="Enter the number of A rated players"
          persistent-hint
          v-model="a"
          :items="aOptions"
          clearable
          color="color3"
          item-color="color3"
          :disabled="aOptions.length === 1"
          dense hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-select
          label="AA Rated Players"
          hint="Enter the number of AA rated players"
          persistent-hint
          v-model="aa"
          :items="aaOptions"
          clearable
          color="color3"
          item-color="color3"
          :disabled="aaOptions.length === 1"
          dense hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-select
          label="AAA Rated Players"
          hint="Enter the number of AAA rated players"
          persistent-hint
          v-model="aaa"
          :items="aaaOptions"
          clearable
          color="color3"
          item-color="color3"
          :disabled="aaaOptions.length === 1"
          dense hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert
          :value="showAlert"
          text
          border="top"
          :type="divisionRating && !divisionRating.startsWith('U') ? 'success' : 'error'"
          transition="scale-transition"
        >
          <span
            v-for="(x, i) in explanation"
            :key="i"
          >
          <v-icon :color="x.startsWith('Not') ? 'error' : 'success'">fas fa-{{x.startsWith('Not') ? 'times' : 'check'}}</v-icon> {{x}}
          </span>
        </v-alert>
      </v-col>
    </v-row>
    <v-slide-x-transition
      group
      hide-on-leave
      tag="div"
      class="row justify-center"
    >
      <v-col
        style="max-width: 325px"
        class="px-0"
        v-for="rating in ratingsList"
        :key="rating.rating"
      >
        <earning-table
          :rating="rating"
          :teams="teams"
          :disabled="divisionRating && rating.rating !== divisionRating"
        ></earning-table>
      </v-col>
    </v-slide-x-transition>
    <v-row dense justify="center">
    </v-row>
  </v-container>
</template>

<script>
import EarningTable from '@/components/Ratings/Explanation/EarningTable'
import { firstBy } from 'thenby'
import { juniors } from '@/classes/VBLRatings.js'

export default {
  props: ['calculator'],
  data () {
    return {
      teams: null,
      divRating: null,
      b: null,
      a: null,
      aa: null,
      aaa: null,
      ratings: juniors
    }
  },
  computed: {
    ratingsList () {
      const base = (this.divisionRating && this.divisionRating !== 'Unsanctioned') ? juniors.filter(f => {
        return !(this.divisionRating && f.rating !== this.divisionRating)
      }) : juniors

      return base.sort(firstBy('order'))
    },
    showAlert () {
      return !!(this.calcDivRating && this.divisionRating) && this.hasEnteredPlayers
    },
    players () {
      return this.teams && (this.teams * 2)
    },
    bOptions () {
      const n = (this.players || 0) - (this.a || 0) - (this.aa || 0) - (this.aaa || 0)
      return [...Array(n + 1).keys()]
    },
    aOptions () {
      const n = (this.players || 0) - (this.b || 0) - (this.aa || 0) - (this.aaa || 0)
      return [...Array(n + 1).keys()]
    },
    aaOptions () {
      const n = (this.players || 0) - (this.b || 0) - (this.a || 0) - (this.aaa || 0)
      return [...Array(n + 1).keys()]
    },
    aaaOptions () {
      const n = (this.players || 0) - (this.b || 0) - (this.a || 0) - (this.aa || 0)
      return [...Array(n + 1).keys()]
    },
    playerObj () {
      const b = (this.b || 0)
      const a = (this.a || 0)
      const aa = (this.aa || 0)
      const aaa = (this.aaa || 0)
      return {
        bOrHigher: b + a + aa + aaa,
        aOrHigher: a + aa + aaa,
        aaOrHigher: aa + aaa,
        aaaOrHigher: aaa
      }
    },
    divisionRating () {
      return this.divRating
    },
    calcDivRating () {
      if (this.teams) {
        if (this.teams > 3) {
          const p = this.playerObj
          if (this.teams >= 12) {
            if (p.aaaOrHigher >= 8) return 'AAA'
            if (p.aaOrHigher >= 8) return 'AA'
            if (p.aOrHigher >= 8) return 'A'
            if (p.bOrHigher >= 8) return 'B'
          } else if (this.teams > 8) {
            if (p.aaaOrHigher >= 10) return 'AAA'
            if (p.aaOrHigher >= 10) return 'AA'
            if (p.aOrHigher >= 10) return 'A'
            if (p.bOrHigher >= 10) return 'B'
          } else {
            if (p.aaaOrHigher >= 12) return 'AAA'
            if (p.aaOrHigher >= 12) return 'AA'
            if (p.aOrHigher >= 12) return 'A'
            if (p.bOrHigher >= 12) return 'B'
          }
          return 'Unrated'
        }
        return 'Unsanctioned'
      }
      return false
    },
    explanation () {
      if (this.teams && this.teams > 3) {
        const p = this.playerObj
        if (this.teams >= 12) {
          if (p.aaaOrHigher >= 8) return ['At least of 12 teams', 'At least 8 AAA or higher players']
          if (p.aaOrHigher >= 8) return ['At least of 12 teams', 'At least 8 AA or higher players']
          if (p.aOrHigher >= 8) return ['At least of 12 teams', 'At least 8 A or higher players']
          if (p.bOrHigher >= 8) return ['At least of 12 teams', 'At least 8 B or higher players']
        } else if (this.teams > 8) {
          if (p.aaaOrHigher >= 10) return ['At least of 8 teams', 'At least 10 AAA or higher players']
          if (p.aaOrHigher >= 10) return ['At least of 8 teams', 'At least 10 AA or higher players']
          if (p.aOrHigher >= 10) return ['At least of 8 teams', 'At least 10 A or higher players']
          if (p.bOrHigher >= 10) return ['At least of 8 teams', 'At least 10 b or higher players']
        } else {
          if (p.aaaOrHigher >= 12) return ['At least 12 AAA or higher players']
          if (p.aaOrHigher >= 12) return ['At least 12 AA or higher players']
          if (p.aOrHigher >= 12) return ['At least 12 A or higher players']
          if (p.bOrHigher >= 12) return ['At least 12 b or higher players']
        }
        return ['At least of 4 teams', 'Not enough rated player']
      }
      return ['Not enough teams to be sanctioned']
    },
    hasEnteredPlayers () {
      return !!(this.b || this.a || this.aa || this.aaa)
    }
  },
  methods: {
    setDivision () {
      if (this.calcDivRating && (this.hasEnteredPlayers || !this.divRating)) {
        if (!this.hasEnteredPlayers && this.calcDivRating.startsWith('U')) return
        this.divRating = this.calcDivRating
      }
    },
    resetPlayers () {
      this.b = null
      this.a = null
      this.aa = null
      this.aaa = null
    }
  },
  watch: {
    teams: function (v) {
      if (!v) {
        this.resetPlayers()
      }
    },
    calcDivRating: 'setDivision',
    b: 'setDivision',
    a: 'setDivision',
    aa: 'setDivision',
    aaa: 'setDivision',
    divRating: function (v) {
      if (v !== this.calcDivRating) {
        this.resetPlayers()
      }
    },
    hasEnteredPlayers: function (v) {
      if (!v) {
        this.divRating = null
      }
    }
  },
  components: {
    EarningTable
  }
}
</script>
